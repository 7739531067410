import React from "react"
import axios from "axios"

import Loader from "../Loader"
import Message from "../Message"
import Pagination from "../Pagination"

import BoxLayout from "../Box"
import BoxTitle from "../Box/title"
import BoxDate from "../Box/date"
import BoxDescription from "../Box/description"
import ResourceLink from "../Box/Links/resource";

const resourcesAPI = process.env.API_RESOURCES

class ResourceList extends React.Component {

    constructor(props) {
        super(props)
        this.state = { 
            resources: [],
            page: 1,
            perPage: 20,
            isLoading: true,
            message: undefined, 
        }
    }

    componentDidMount = () => {
        const {page, perPage} = this.state
        this.getResources(page, perPage)
    }
        
    getResources = (page, perPage) => {
        axios.get(`${resourcesAPI}?page=${page}&per-page=${perPage}`)
        .then(response => {
            this.setState({ 
                resources: response.data.resources,
                total: response.data.total,
                page: page,
                isLoading: false
            })
        })
        .catch(error => {
            this.setState({
                message: {
                    type: "danger",
                    text: error.response.data.message
                },
                isLoading: false
            })
        })
    }

    render() {
        const {isLoading, message, resources, page, perPage, total} = this.state
        let resourceList = resources.map(resource => (
            <BoxLayout key={resource.id}>
                <BoxTitle name={resource.name} />
                <BoxDate date={resource.date} />
                <BoxDescription description={resource.description} />
                <ResourceLink file={resource.file} icon="download" text="Download" />
            </BoxLayout>
        ))
        if(isLoading === true) {
            return(
                <Loader />
            )
        } else if(message) {
            return(
                <Message type={message.type} message={message.text} />
            )
        } else {
            return(
                <>
                <div className="row">
                    {resourceList}
                </div>
                <div className="row">
                    <Pagination page={page} perPage={perPage} total={total} updateFunc={this.getResources} />
                </div>
                </>
            )
        }
    }
}

export default ResourceList