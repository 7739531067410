import React from "react"

import Layout from "../components/layout"
import Page from "../components/Page/page"

import ResourceList from "../components/Resource/list"

class Resources extends React.Component {
  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <Page id="12" />
        <ResourceList />
      </Layout>
    )
  }
}

export default Resources
