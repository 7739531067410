import React from "react"

import Icon from "../../Icon"

const fileDirectory = process.env.FILE_BUCKET

const ResourceLink = ({ file, icon, text }) => {
    if(file !== "") {
        return(
            <div className="link-container">
                <a href={`${fileDirectory}/resources/${file}`} download target="_blank" rel="noopener noreferrer"><Icon prefix="fas" name={icon} />{text}</a>
            </div>
        )
    } else {
        return(null)
    }
}

export default ResourceLink